import classnames from "classnames";
import { motion } from "framer-motion";

import MetricTooltip from "../ui-elements/tooltip";

interface Card {
  title: string;
  emoji: string;
  description: string;
}

const cards: Card[] = [
  {
    title: "Muscle Mass",
    emoji: "💪",
    description: "Amount of muscle in the body",
  },
  {
    title: "Memory",
    emoji: "🤔",
    description: "Ability to retain and recall information",
  },
  {
    title: "Flexibility & Range of Motion",
    emoji: "🤸",
    description: "Capacity to move joints through their full range",
  },
  {
    title: "Emotional Reactivity",
    emoji: "😔",
    description: "Responsiveness to emotional stimuli",
  },
  {
    title: "Cholestorol",
    emoji: "🧈",
    description: "Fat-like substance in blood",
  },
  {
    title: "Microbiome",
    emoji: "🦠",
    description: "Community of microorganisms in the digestive system",
  },
  {
    title: "Body Water",
    emoji: "💧",
    description: "Total amount of water in the body",
  },
  {
    title: "Sleep Quality",
    emoji: "💤",
    description: "Effectiveness of sleep in restoring energy and health",
  },
  {
    title: "Resting Heart Rate",
    emoji: "💗",
    description: "Your average heart rate when you're at rest",
  },
  {
    title: "Submaximal Fitness",
    emoji: "🚴‍♂️",
    description: "Endurance level below maximal effort",
  },
  {
    title: "Problem Solving",
    emoji: "💡",
    description: "Ability to to use logic and notice patterns",
  },
  {
    title: "Estrogens",
    emoji: "♀️",
    description: "Hormones important for reproductive health",
  },
  {
    title: "Inflammation",
    emoji: "🎈",
    description: "Body’s response to injury or infection",
  },
  {
    title: "Triglycerides",
    emoji: "🍞",
    description: "Type of fat in the blood, linked to heart disease.",
  },
  {
    title: "Cortisol Levels",
    emoji: "😰",
    description: "Amount of stress hormone in the body",
  },
  {
    title: "pH Level",
    emoji: "🧪",
    description: "Measure of acidity or alkalinity in the body",
  },
  {
    title: "Max Strength",
    emoji: "🏋️‍♂️",
    description: "Maximum force a muscle can exert in a single effort",
  },
  {
    title: "Trace Elements",
    emoji: "🌱",
    description: "Minerals required in small amounts for health",
  },
  {
    title: "Blood Glucose",
    emoji: "🩸",
    description: "Sugar concentration in the blood",
  },
  {
    title: "Thyroid Hormones",
    emoji: "🧣",
    description: "Hormones regulating metabolism and energy",
  },
  {
    title: "Isometric Strength",
    emoji: "🏌️‍♂️",
    description: "Strength during muscle contraction without movement",
  },
  {
    title: "Heart Rate Patterns",
    emoji: "💗",
    description: "Rhythmic changes in heart rate",
  },
  {
    title: "Vitamins",
    emoji: "💊",
    description: "Vitamin content in the body",
  },
  {
    title: "Insulin Resistance",
    emoji: "🍬",
    description:
      "Reduced response of cells to insulin, leading to high blood sugar.",
  },
  {
    title: "Attention",
    emoji: "🤓",
    description: "Ability to focus on tasks without distraction",
  },
  {
    title: "Reaction Time",
    emoji: "💥",
    description: "Speed at which the body responds to stimuli",
  },
  {
    title: "Learning Speed",
    emoji: "👶",
    description: "Rate at which you can acquire new information",
  },
  {
    title: "Adrenal Cortex Hormones",
    emoji: "📄",
    description:
      "Hormones produced by the adrenal glands affecting stress and metabolism",
  },
  {
    title: "Subjective Mood Patterns",
    emoji: "😟",
    description: "Personal perception of mood over time",
  },
  {
    title: "Bowel Movements",
    emoji: "💩",
    description: "Frequency and quality of stool",
  },
  {
    title: "Bone Density",
    emoji: "🦴",
    description: "Density and resilience of bones",
  },
  {
    title: "Progesterones",
    emoji: "📄",
    description: "Hormones important for regulating menstruation and pregnancy",
  },
  {
    title: "Cardio Recovery",
    emoji: "🏃‍♂️",
    description: "Heart rate recovery after physical exertion",
  },

  {
    title: "Adrenal Androgens",
    emoji: "📄",
    description:
      "Androgens produced by adrenal glands influencing reproductive health",
  },
  {
    title: "Synaptic Density",
    emoji: "🧪",
    description: "Concentration of connections between neurons",
  },
  {
    title: "Balance",
    emoji: "🧗",
    description: "Ability to maintain stability and control movement",
  },
  {
    title: "Gut Permeability",
    emoji: "🦧",
    description: "Level of permeability in the gut lining",
  },
];

const TrackItImproveIt = () => {
  return (
    <section className="flex w-screen max-w-screen-xl flex-col items-center justify-center gap-8 px-6 sm:gap-12 sm:px-12">
      <motion.h2
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.2 }}
        viewport={{ once: true }}
        className={classnames(
          "w-full text-left font-semibold text-base-content/30",
          "text-3xl md:text-4xl",
        )}
      >
        <span className="whitespace-nowrap bg-gradient-to-r from-base-content/60 to-base-content bg-clip-text font-semibold text-transparent">
          If you can track it
        </span>
        , you can improve it
      </motion.h2>
      <div
        className={classnames(
          "max-w-screen-xl",
          "flex flex-row flex-wrap",
          "-ml-4 gap-1 sm:gap-2 md:gap-4",
        )}
      >
        {cards.map(Card)}
      </div>
    </section>
  );
};

const Card = ({ emoji, title, description }: Card, i: number) => {
  return (
    <MetricTooltip
      content={description}
      key={title}
      classnames="bg-base-100/60 text-base-content"
    >
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 0.3, delay: 0.05 * i }}
        viewport={{ once: true }}
        className={classnames(
          "flex flex-row items-center justify-start gap-4 rounded-2xl",
          "w-fit px-4 py-2",
          "hover:bg-base-200",
        )}
      >
        <p className="w-3 text-sm md:text-base lg:text-lg">{emoji}</p>
        <h6
          className={classnames(
            "text-sm md:text-base lg:text-lg",
            "w-fit whitespace-nowrap",
          )}
        >
          {title}
        </h6>
      </motion.div>
    </MetricTooltip>
  );
};

export default TrackItImproveIt;
