import Image from "next/image";
import Link from "next/link";
import {
  faArrowUpRight,
  faNewspaper,
} from "@fortawesome/pro-regular-svg-icons";
import classnames from "classnames";

import Button from "../ui-elements/Button";
import WaitlistDialog from "../ui-elements/WaitlistDialog";

const MainCta = () => {
  return (
    <section
      className={classnames(
        "relative -mb-28 flex w-screen flex-col items-center justify-center gap-12 overflow-hidden md:-mb-40 ",
        "h-[540px] sm:h-[420px] md:h-[600px]",
      )}
    >
      <Image
        src="/images/home/gradient-circles-light.png"
        alt="preventative health routine calendar and holistic protocol stack"
        className="absolute z-0 object-cover dark:hidden"
        fill
      />
      <Image
        src="/images/home/gradient-circles-dark.png"
        alt="preventative health routine calendar and holistic protocol stack"
        className="absolute z-0 hidden object-cover dark:block"
        fill
      />
      <div className="light z-10 flex flex-col items-center justify-center gap-3 px-6 text-center text-base-content md:gap-6">
        <h2 className="text-3xl font-bold md:text-5xl ">
          Unleash your potential
        </h2>
        <p className="text-xl font-light md:text-2xl">
          Sign up for a free account to{" "}
          <span className="whitespace-nowrap font-light">get started.</span>
        </p>
      </div>
      <div className="light z-10 flex gap-4">
        <Link href="/blog">
          <Button variant={"outline"} rightIcon={faNewspaper}>
            Learn more
          </Button>
        </Link>
        <WaitlistDialog>
          <Button variant={"solid"} rightIcon={faArrowUpRight}>
            Sign up
          </Button>
        </WaitlistDialog>
      </div>
    </section>
  );
};

export default MainCta;
